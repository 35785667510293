import React from 'react';
import classNames from 'classnames';
import Button from '@mui/material/Button';
import {getDateDiff} from '../../../../../modules/format/formatDate';
import {Amount} from '../../../common/Amount/Amount';
import styles from './BondCard.module.sass';

export const BondCard = ({bond, bondInfo, colorScale, measuredValue}) => {
  return (
    <div
      className={styles.container}
      style={{background: colorScale(bond[measuredValue])}}
    >
      <div className={styles.title}>
        <div className={styles.titleName} title={bond.issuerName}>{bond.issuerName}</div>
        <div className={styles.titleNumber}>
          <div className={styles.issueNumber} title={bond.issueNumber}>
            {bond.issueNumber}
          </div>
          <div className={classNames(styles.ratingStatus, `bgRating${bond.ratingGroup.replace('+', 'P')}`)}>
            {bond.nationalRating}
          </div>
        </div>
      </div>
      <div className={styles.text}>
        {'Выгоднее депозита на'}
      </div>
      <div className={styles.differenceWithDeposit}>
        <Amount value={bond.differenceWithDeposit} />
      </div>
      <div className={styles.result}>
        <div className={styles.deposite_row}>
          <div>Заработаю</div>
          <div><Amount value={bond.income} /></div>
        </div>
        <div className={styles.deposite_row}>
          <div>Через</div>
          <span>{getDateDiff(bond.redemptionDate)}</span>
        </div>
      </div>
      <Button
        className={styles.buttonBuy}
        variant="contained"
        size={'large'}
      >
        Купить
      </Button>
    </div>
  );
};
