import React, { useRef, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {pluralize} from '../../../../../modules/pluralize';
import LoadingSpinner from '../LoadingSpinner';
import {formatPercent} from '../../../../../modules/format';
import { ReactComponent as ExportIcon} from "../../../../images/union.svg";
import {Amount} from '../../../common/Amount/Amount';
import {BondCard} from './BondCard';
import {SUGGESTION_CONTAINER_CLASSNAME} from './BondSuggestion';
import styles from './SuggestionsPopup.module.sass';
import homeStyles from '../styles.module.sass';

function useOutsideAlerter(ref, parentElement, onClose) {
  /** NB: проверяем наличие css класса у текущего элемента и его родителей */
  function hasParentWithMatchingSelector (target, selector) {
    return [...document.querySelectorAll(selector)].some(el =>
      el !== target && el.contains(target)
    )
  }

  useEffect(() => {
    function handleClickOutside(event) {
      /** NB: закрываем предложения если кликнули вне открытых предложений или в карточку. */
      if (
        ref.current
        && !ref.current.contains(event.target)
        && !parentElement.contains(event.target)
        && event.target.nodeName !== 'HTML'
        && !hasParentWithMatchingSelector(event.target, `.${SUGGESTION_CONTAINER_CLASSNAME}`)
      ) {
        onClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, parentElement]);
}

export const SuggestionsPopup = ({
  bonds,
  onClose,
  colorScale,
  pending,
  error,
  bondsInfoMap,
  parentElement,
  measuredValue
}) => {

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, parentElement, onClose);

  return (
    <div className={styles.container} ref={wrapperRef}>
      <div className={styles.close} onClick={onClose}><CloseIcon /></div>
      <div className={styles.deposite}>
        <div className={styles.deposite_content}>
          <ExportIcon />
          <div className={styles.deposite_title}>{`Банковский депозит ${formatPercent(bonds[0].depositYield)}`}</div>
          <div className={styles.deposite_row}>
            <div>Заработаю</div>
            <div><Amount value={bonds[0].depositIncome} /></div>
          </div>
          <div className={styles.deposite_row}>
            <div>Через</div>
            <div>{bonds[0].periodLabel}</div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{bonds.length} {pluralize(bonds.length, ['облигация', 'облигации', 'облигаций'])}</div>
        {error && (
          <div className={homeStyles.messageCover}>
            <div className={homeStyles.messageCover_error}>
              {'Ошибка загрузки данных'}
            </div>
          </div>
        )}
        {pending && (
          <div className={homeStyles.messageCover}>
            <div className={homeStyles.messageCover_caption}>
              {'Загрузка'}
            </div>
            <LoadingSpinner />
          </div>
        )}
        {!pending && !error && (
          <div className={styles.bonds}>
            {bonds.map((bond) => (
              <BondCard
                colorScale={colorScale}
                key={bond.isin}
                bond={bond}
                bondInfo={bondsInfoMap[bond.isin]}
                measuredValue={measuredValue}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
