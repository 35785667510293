import React from 'react';
import classNames from 'classnames';
import styles from './Button.module.sass';

export const Button = ({children, isActive, onClick}) => (
  <button
    className={classNames(styles.button, {[styles.isActive]: isActive})}
    onClick={onClick}
  >
    {children}
  </button>
);
