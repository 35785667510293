import React from 'react';
import Modal from '@mui/material/Modal';
import styles from './OfflineExchangeModal.module.sass';
import icon from './icon.svg';

export const OfflineExchangeModal = ({isOpen, onClose}) => (
  <Modal
    disableAutoFocus
    open={isOpen}
  >
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.close} onClick={onClose} />
        <img src={icon} alt="" />
        <div className={styles.title}>
          Мы работаем во время торгов
          <br />
          на Московской бирже
        </div>
        <div className={styles.description}>
          Ждем вас в рабочие дни с 10 до 18:30
        </div>
        <div className={styles}></div>
      </div>
    </div>
  </Modal>
);
