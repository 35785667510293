import {scalePow} from 'd3-scale';
import lodash from 'lodash';

export const getColorScale = (values) => {
  const minValue = lodash.min(values);
  const maxValue = lodash.max(values);

  const negativeScale = scalePow()
    .exponent(2.5)
    .domain([minValue, 0])
    .range(['#E8B7FF', '#FAEEFF']);
  const positiveScale = scalePow()
    .exponent(2.5)
    .domain([0, maxValue])
    .range(['#D4EDFF', '#4FB6FF']);

  return (totalValue) => {
    if (totalValue >= 0) {
      return positiveScale(totalValue);
    }
    return negativeScale(totalValue);
  }
};
